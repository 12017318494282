const HIDE_RESET_MODAL = (state, payload) => {
  state.resetModal = {
    show: false,
    old_password:null,
    password:null,
    confirm_password:null
  }
}

const SHOW_RESET_MODAL = (state, payload) => {
  state.resetModal.show = true
}

export default {
  HIDE_RESET_MODAL,
  SHOW_RESET_MODAL,
}
